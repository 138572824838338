<template>
  <div class="content-audit">
    <div class="content-global content-tables-audit">
      <div ref="searchbarGlobal">
        <header-component-global
          sTextTitle="Auditorías"
          sTextBtnAdd="Recargar"
          :bAdminPermissions="true"
          :toVieworModal="true"
        />
      </div>
      <div ref="filterGlobal">
        <filter-component-global
          class="global-filters"
          labelName="Buscador de auditoría"
          placeholderName="Ingrese nombre del usuario, acción registrada..."
          @setSearch="setSearch"
          @setAssociatedModule="setAssociatedModule"
        />
      </div>
      <div dark class="global-content-table table-audit">
        <no-data-text-component-global
          :iHeight="screenHeight"
          :sTextEmpty="sTextEmpty"
          v-if="bEmptyTable"
          :bAdminPermissions="false"
          :toVieworModal="true"
        />
        <v-data-table
          v-else
          v-show="!isMobile"
          mobile-breakpoint="0"
          :headers="headers"
          :disable-sort="true"
          :items="aTable"
          :loading="bLoadingTable"
          loading-text="Cargando datos..."
          :items-per-page="iItemsPerPage"
          :hide-default-footer="true"
          no-data-text="No hay información disponible para mostrar."
          :footer-props="{
            'items-per-page-text': 'Filas por página',
            'items-per-page-all-text': 'Todos',
          }"
          class="global-table"
        >
          <template slot="progress">
            <v-progress-linear
              color="var(--primary-color-loading-table)"
              indeterminate
            ></v-progress-linear>
          </template>
          <template v-slot:[`item.sIcon`]>
            <!-- icon -->
            <v-icon class="icon-audit-arrow" color="var(--primary-color-text)">
              mdi-chevron-up
            </v-icon>
            <!-- <span class="material-icons">
                            expand_less
                        </span> -->
          </template>
          <template v-slot:[`item.sNameAuditor`]="{ item }">
            <v-icon @click="goToUser(item.sLogId)" class="text-icon-launch">
              mdi-launch</v-icon
            >
            <span @click="goToUser(item.sLogId)" class="text-information-link">
              {{ item.sNameAuditor }}
            </span>
          </template>
          <template v-slot:footer>
            <footer-table-component-global
              :numPages="numPages"
              :totalItems="totalItems"
              :bInitialCurrentPage="bInitialCurrentPage"
              @changeTable="getEmitAudit"
            />
          </template>
        </v-data-table>
        <table-mobile-audit-component
          v-show="isMobile"
          @changeTable="getEmitAudit"
          @openDetailItem="detailAudit"
          @openDeleteItem="deleteItem"
          :aTable="aTable"
          :headers="headers"
          :numPages="numPages"
          :totalItems="totalItems"
          :bInitialCurrentPage="bInitialCurrentPage"
          @goToUser="goToUser"
        />
      </div>
    </div>
    <delete-component-global
      @setDialogDelete="deleteItem"
      :oDialogDelete="oDialogDelete"
      :bDialogDelete="bDialogDelete"
    />
    <!-- <footer-component-global /> -->
  </div>
</template>

<script>
export default {
  name: "audit",
  data() {
    return {
      sSearch: "",
      sAssociatedModule: "",
      screenHeight: 0,
      heightMax: 0,
      screenWidth: 0,
      bLoadingTable: true,
      bEmptyTable: false,
      iCurrentPage: 1,
      bInitialCurrentPage: false,
      iPageNumber: 1,
      iItemsPerPage: 30,
      numPages: 0,
      totalItems: 0,
      totalView: 0,
      sTextEmpty: "",

      headers: [
        {
          text: "",
          sortable: false,
          value: "sIcon",
          class: "global-header-table ",
          cellClass: "global-body-table  thIcon ",
        },
        {
          text: "Nombre del módulo",
          sortable: false,
          value: "sNameModule",
          class: "global-header-table",
          cellClass: "global-body-table  w-300",
        },
        {
          text: "Nombre del usuario",
          sortable: false,
          value: "sNameAuditor",
          class: "global-header-table",
          cellClass: "global-body-table ",
        },
        {
          text: "Acción registrada",
          sortable: false,
          value: "sDescription",
          class: "global-header-table ",
          cellClass: "global-body-table global-body-action-register-audit",
        },
        {
          text: "Fecha y hora de creación",
          sortable: false,
          value: "tCreatedAt",
          class: "global-header-table ",
          cellClass: "global-body-table w-250",
          align: "center",
        },
        // {
        //     text: "Acciones",
        //     value: "accions",
        //     align: "center",
        //     class:
        //         "global-header-table  global-header-accions global-col-header-accion",
        //     cellClass:
        //         "global-body-table global-body-accions global-col-body-accion",
        // },
      ],
      aTable: [],
      aMonth: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
      ],
      fechaIngreso: "",
      textAlfabetico: "",
      isMobile: false,
      bDialogDelete: false,
      oDialogDelete: {
        active: false,
        textTitle: "",
        textDescription: "",
        textQuestion: "",
        api: "",
        returnToView: false,
      },
    };
  },
  beforeMount() {
    this.$store.commit("setTabPosition", { sTabPosition: 0 });
    this.$store.commit("setTabPositionOrderPayable", 0);

    this.setTextEmpty();
    this.getAudit();
  },
  updated() {
    this.matchHeight();
  },
  created() {
    window.addEventListener("resize", this.handleResize);
    this.handleResize();
    window.addEventListener("resize ", this.matchHeight);
    this.matchHeight();
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.addEventListener("resize ", this.matchHeight);
  },
  methods: {
    setSortName(val) {
      this.textAlfabetico = val;
    },
    setSortDate(val) {
      this.fechaIngreso = val;
    },
    setTextEmpty() {
      let sTextemptyTemp = " <p>No existen auditorías registradas.</p>";
      // this.bAdminAudit ? sTextemptyTemp += "<p>Comienza <span  class='text-information-link'>registrando uno.</span> </p>" : ""
      this.sTextEmpty = sTextemptyTemp;
    },

    //#region RESIZE PAGE
    matchHeight() {
      this.$nextTick((e) => {
        if (this.$refs.searchbarGlobal !== undefined) {
          this.heightMax =
            this.$refs.searchbarGlobal.clientHeight +
            this.$refs.filterGlobal.clientHeight +
            90;
        }
        if (window.innerWidth > 599.99) {
          this.isMobile = false;
          this.screenHeight = window.innerHeight - this.heightMax;
        } else {
          this.isMobile = true;
          this.screenHeight = window.innerHeight - this.heightMax;
        }
      });
    },
    handleResize: function () {
      this.matchHeight();
    },
    //#endregion RESIZE PAGE
    deleteItem(item) {
      if (item) {
        this.oDialogDelete.textTitle = "Eliminar cliente";
        this.oDialogDelete.textDescription =
          "La eliminación de un cliente es una acción irreversible.";
        this.oDialogDelete.textQuestion =
          "Acepto la responsabilidad y deseo proseguir con la eliminación del cliente: " +
          item.sPublicName +
          ".";
        this.oDialogDelete.api = `${URI}/api/sp/v1/customers/${item.sCustomerId}`;
      }
      this.bDialogDelete = !this.bDialogDelete;
    },
    detailAudit(item) {
      this.$router
        .push(`/home/customer-detail/${item.sCustomerId}`)
        .catch(() => {});
    },
    getEmitAudit(obj) {
      this.iCurrentPage = obj.iCurrentPage;
      this.iItemsPerPage = obj.iItemsPerPage;
      this.getAudit();
    },
    getAudit() {
      this.$store
        .dispatch("getPermissionsByUserGlobal")
        .then((resp) => {
          DB.get(`${URI}/api/sp/v1/audit`, {
            params: {
              iPageNumber: this.iCurrentPage,
              iItemsPerPage: this.iItemsPerPage,
              sSearch: this.sSearch,
              sModuleId: this.sAssociatedModule,
            },
            headers: {
              Authorization: "Bearer " + this.$store.state.sToken,
            },
          })
            .then((response) => {
              this.numPages = response.data.numPages;
              this.totalItems = response.data.total;
              this.aTable = response.data.results.map((e) => {
                return {
                  ...e,
                  sNameModule: e.oModule.sName,
                  sNameAuditor: e.oUser.sFullName,
                  sAuditorId: e.oUser.sUserId,
                  sDescription: e.sDescription,
                  tCreatedAt: e.tCreatedAt,
                };
              });
              this.bLoadingTable = false;
              this.bEmptyTable = this.aTable.length < 1;

              this.totalView = this.aTable.length;
              this.$store.commit("refresher", false);
            })
            .catch((error) => {
              this.bLoadingTable = false;
              this.Error(error.response.data);
            });
        })
        .catch((err) => {
          this.Error(err);
        });
    },
    goToUser(sUserId) {
      this.$router
        .push({
          name: "UserProfileDetailAudit",
          params: { idUser: sUserId },
        })
        .catch(() => {});
    },
    formatPhone(oContactInfo) {
      let sCountryCallingCode = oContactInfo.sCountryCallingCode;
      switch (sCountryCallingCode) {
        case "52":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 4),
              "-",
              oContactInfo.sPhoneNumber.slice(4),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        case "1":
          return (
            "+" +
            oContactInfo.sCountryCallingCode +
            " (" +
            oContactInfo.sAreaCallingCode +
            ") " +
            [
              oContactInfo.sPhoneNumber.slice(0, 3),
              "-",
              oContactInfo.sPhoneNumber.slice(3),
            ].join("") +
            (oContactInfo.sPhoneExtension
              ? " ext. " + oContactInfo.sPhoneExtension + ""
              : "")
          );

        default:
          break;
      }
    },
    setSearch(sSearch) {
      this.sSearch = sSearch;
    },
    setAssociatedModule(sAssociatedModule) {
      this.bInitialCurrentPage = !this.bInitialCurrentPage;
      this.sAssociatedModule = sAssociatedModule;
    },
    // FUNCTION OF FOOTER TABLE
    changeTable(e) {
      this.iItemsPerPage = e;
      this.iCurrentPage = 1;
      this.getAudit();
    },
    backPage() {
      if (this.iCurrentPage > 1) {
        this.iCurrentPage--;
      }
    },
    nextPage() {
      if (this.iCurrentPage < this.numPages) {
        this.iCurrentPage++;
      }
    },
    firstPage() {
      this.iCurrentPage = 1;
    },
    lastPage() {
      this.iCurrentPage = this.numPages;
    },
  },
  computed: {
    refreshTable() {
      return this.$store.state.refresh;
    },
    bShowAudit() {
      return this.$store.state.bShowAudit;
    },
    bAdminAudit() {
      return this.$store.state.bAdminAudit;
    },
  },
  watch: {
    refreshTable() {
      this.bLoadingTable = true;
      this.bEmptyTable = false;
      this.aTable = [];

      this.getAudit();
    },
    sSearch() {
      this.iCurrentPage = 1;
      this.getAudit();
    },
    sAssociatedModule() {
      this.iCurrentPage = 1;
      this.getAudit();
    },
    iCurrentPage() {
      this.getAudit();
    },
  },
};
</script>

<style scoped>
.content-audit {
  /* background-color: darkslategrey; */
  padding: 0px 30px 0px 35px;
}

.text-icon-launch {
  color: var(--primary-color-link);
  /* margin-top: -5px; */
  font-size: 20px;
  cursor: pointer;
}
</style>
